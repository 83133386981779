<template>
    <div class="about">骑驴，探索奇妙世界的平台。</div>
</template>

<script>
    export default {
        name: "About",
        mounted(){}
    }
</script>

<style lang="less" scoped>
    .about{
        font-size:20px;
    }
</style>